<script setup lang="ts">
import { Swiper as SwiperClass } from "swiper";
import { vIntersectionObserver } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VDisclaimer from "@magnit/core/src/containers/VDisclaimer/VDisclaimer.vue";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";

const swiper = ref<Nullable<SwiperClass>>();
const { requestPromotionsByType } = promoApi();
const { status, data: slides } = await requestPromotionsByType({
  type: [PromotionType.Selection],
  limit: 10,
});
const onSwiper = (s: SwiperClass) => (swiper.value = s);

const { send } = useAnalytics();
const handleViewPopularSection = ([
  { isIntersecting },
]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:Popular:View");
  }
};
const handleViewPopularItem = (
  [{ isIntersecting }]: IntersectionObserverEntry[],
  item: IProduct,
  index: number,
) => {
  if (!isIntersecting) return;
  send("MainPage:Popular:Item:View", {
    item_id: item.id,
    dest: item.link,
    promo_name: item.title,
    position: index,
  });
};
const handleClickPopularItem = (item: IProduct, index: number) => {
  send("MainPage:Popular:Item:Click", {
    item_id: item.id,
    dest: item.link,
    promo_name: item.title,
    position: index,
  });
};
</script>

<template>
  <VSkeletonPopularSlider v-if="status === 'pending'" />
  <section
    v-else-if="slides?.goods.length"
    v-intersection-observer="handleViewPopularSection"
    class="popular"
  >
    <div class="popular__title-wrapper">
      <VWrapperLocal>
        <VText :font="{ xs: 'headline-small', l: 'headline-large' }">
          Популярные акции
        </VText>
      </VWrapperLocal>
    </div>
    <VWrapper :indent="{ l: '32px' }">
      <AppSlider
        autoplay
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 24,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
            pagination: {
              enabled: true,
            },
          },
          xl: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
            pagination: {
              enabled: true,
            },
          },
        }"
        @on-swiper="onSwiper"
      >
        <AppSliderSlide
          v-for="(item, index) in slides?.goods"
          :key="item.id"
          :size="{ xs: 6, m: 4 }"
        >
          <VAppLink
            :to="item.link"
            :aria-label="item.title"
            class="pl-hover-base"
            @click="handleClickPopularItem(item, index)"
          >
            <div
              v-intersection-observer="
                (arg) => handleViewPopularItem(arg, item, index)
              "
              class="popular__slide"
            >
              <AppImage :src="item.image" class="popular__slide-image" />
              <VDisclaimer v-if="item.eridInfo" :content="item.eridInfo" />
            </div>
          </VAppLink>
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </section>
</template>

<style scoped lang="postcss">
.popular {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 366px;

  @media (--pl-viewport-from-m) {
    min-height: 380px;
  }

  @media (--pl-viewport-from-ml) {
    min-height: 260px;
  }

  @media (--pl-viewport-from-l) {
    min-height: 477px;
  }

  &__title-wrapper {
    margin-top: var(--pl-unit-x8);
    margin-bottom: var(--pl-unit-x2);

    @media (--pl-viewport-from-l) {
      margin-top: calc(var(--pl-unit-x6) * 2);
      margin-bottom: var(--pl-unit-x4);
    }
  }

  &__slide {
    width: 100%;
    aspect-ratio: 0.6;
    display: flex;

    @media (--pl-viewport-from-xl) {
      aspect-ratio: 0.73;
    }
  }

  :deep(.popular__slide-image) {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  @media (--pl-viewport-from-ml) {
    padding-bottom: var(--pl-unit-x4);
  }

  :deep(.slider-slide) {
    overflow: hidden;
    border-radius: var(--pl-unit-x4);

    @media (--pl-viewport-from-ml) {
      border-radius: var(--pl-unit-x5);
    }

    @media (--pl-viewport-from-xl) {
      border-radius: var(--pl-unit-x6);
    }
  }
}
</style>
